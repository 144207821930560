import { useRef } from "react";
import { Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import "./App.css";
import CustomAppBar from "Components/CustomAppbar";
import CustomFooter from "Components/CustomFooter";
import DataContextProvider from "Contexts/DataContext";
import HomePage from "Pages/Home";
import PastDrawsPage from "Pages/PastDraws";
import { PathsUrls } from "Utils/Data";
import DailyEntriesPage from "Pages/DailyEntries";
import { getDefaultRaffleIDLst, getPageMsgLst } from "./UtilFns";

const theme = createTheme({
  palette: {
    primary: {
      main: "#192B4A",
    },
    secondary: {
      main: "#192B4A",
    },
    highlight: "#d1ac00",
  },
  typography: {
    fontFamily: "Roboto",
  },
});

function App() {
  // #region INIT DATA

  const pageMsgLst = getPageMsgLst();
  const raffleIDLst = getDefaultRaffleIDLst();
  // #endregion

  // #region URL PARAMS
  // const url = new URL(window.location.href);
  // const raffleIDUrlParam = url.searchParams.get("raffleId") ?? null;

  // #endregion

  // #region INIT DATA API
  // const {
  //   isLoading: isLoadingGetDynamicDataAPI,
  //   isSuccess: isSuccessGetDynamicDataAPI,
  //   data: getDynamicDataAPIResponse,
  // } = useGetDynamicDataQuery();

  // if (isLoadingGetDynamicDataAPI) {
  //   pageContent = (
  //     <Backdrop
  //       open={true}
  //       sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //     >
  //       <CircularProgress />
  //     </Backdrop>
  //   );
  // } else {
  //   if (isSuccessGetDynamicDataAPI) {
  // SET PAGE MESSAGES
  // pageMsgLst.homePageMsg =
  //   getDynamicDataAPIResponse.data.messages.home_page_screen_text;
  // pageMsgLst.pastDrawsPageMsg =
  //   getDynamicDataAPIResponse.data.messages.past_draws_page_screen_text;
  // pageMsgLst.dailyEntriesPageMsg =
  //   getDynamicDataAPIResponse.data.messages.daily_entries_page_screen_text;

  // if (
  //   Array.isArray(getDynamicDataAPIResponse.data.raffle_ids.raffle_id) &&
  //   getDynamicDataAPIResponse.data.raffle_ids.raffle_id.length > 0
  // ) {
  //   raffleIDLst = [...getDynamicDataAPIResponse.data.raffle_ids.raffle_id];

  // RAFFLE ID FROM URL PARAM, OTHERWISE FROM API RESPONSE
  // const defaultRaffleId = parseInt(
  //   raffleIDUrlParam
  //     ? raffleIDUrlParam
  //     : getDynamicDataAPIResponse.data.raffle_ids.default_raffle_id
  // );

  // const defaultRaffleId = 3;

  // let defaultRaffle_ArrayIndex = raffleIDLst.findIndex(
  //   (item) => item.id === defaultRaffleId
  // );

  // REORDER THE LIST(DEFAULT RAFFLE COMES FIRST)
  // if (defaultRaffle_ArrayIndex > -1) {
  //   //REMOVE THE ELEMENT AND INSERT AGAIN AT START
  //   raffleIDLst.unshift(raffleIDLst.splice(defaultRaffle_ArrayIndex, 1)[0]);
  // }
  //   }
  // }

  // #endregion

  const commonObj = {
    iframeRef: useRef(null),
    pageMsgLst,
    RaffleIDList: raffleIDLst,
  };

  const pageContent = (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <CustomAppBar />
        <DataContextProvider value={commonObj}>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path={PathsUrls.pastDraws} element={<PastDrawsPage />} />
            <Route
              path={PathsUrls.dailyEntries}
              element={<DailyEntriesPage />}
            />
          </Routes>

          <CustomFooter />
        </DataContextProvider>
      </CssBaseline>
    </ThemeProvider>
  );
  // }

  return pageContent;
}

export default App;
