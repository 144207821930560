import { useEffect, useState, useContext } from "react";

import { Box, Typography, Fade } from "@mui/material";
import "./winMessage.css";
import { dataContext } from "Contexts/DataContext";
import { winMsgIntervalTimerDuration } from "Utils/Data";

function WinMessage({ pageName }) {
  const [messageIndex, setMessageIndex] = useState(0);
  const msgLst = useContext(dataContext).pageMsgLst[pageName] ?? [];

  // 15 seconds interval
  useEffect(() => {
    let interval = null;

    if (msgLst.length > 1) {
      interval = setInterval(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % msgLst.length);
      }, winMsgIntervalTimerDuration);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  if (msgLst.length === 0) {
    return null;
  }

  if (msgLst.length === 1) {
    return (
      <Box backgroundColor="secondary.main" className="win-message-text">
        <Typography>{msgLst[0]}</Typography>
      </Box>
    );
  }

  const currentMsg = msgLst[messageIndex];

  return (
    <Box backgroundColor="secondary.main" className="win-message-text">
      <Fade in timeout={1500}>
        <Typography>{currentMsg}</Typography>
      </Fade>
    </Box>
  );
}

export default WinMessage;
