import { Box, Typography } from "@mui/material";
import "./footer.css";

function CustomFooter() {
  return (
    <Box className="footer-div">
      <Typography color="primary.main" className="footer-text">
        Copyright &#64; 747 {new Date().getFullYear()} All Rights Reserved.
      </Typography>
    </Box>
  );
}

export default CustomFooter;
