// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appbar-main-div {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 12vh;
  min-height: 60px;
  width: 100%;
}

.appbar-logo {
  height: 7vh;
  min-height: 40px;
  margin-inline: 15px;
}

.join-raffle-btn {
  color: white;
  border-color: white;
  padding: 5px 10px;
}

.join-raffle-btn:enabled {
  border-color: white;
}

.appbar-hamburger-div {
  display: block;
}

.appbar-menuItem-div {
  display: none;
}

.appbar-menuItem {
  margin-inline: 10px;
  font-size: 1rem;
  cursor: pointer;
}

.appbar-mobile-menuItem-div {
  display: flex;
}

.appbar-mobile-menuItem {
  margin: 5px 10px;
  font-size: 1rem;
  cursor: pointer;
}

/*LARGE*/
@media screen and (min-width: 768px) {
  .appbar-main-div {
    justify-content: space-between;
  }

  .appbar-menuItem-div {
    display: flex;
    margin-right: 10px;
  }

  .appbar-mobile-menuItem-div {
    display: none;
  }

  .appbar-hamburger-div {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/CustomAppbar/appbar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;;EAE9B,YAAY;EACZ,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;;AAEA,QAAQ;AACR;EACE;IACE,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".appbar-main-div {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n\r\n  height: 12vh;\r\n  min-height: 60px;\r\n  width: 100%;\r\n}\r\n\r\n.appbar-logo {\r\n  height: 7vh;\r\n  min-height: 40px;\r\n  margin-inline: 15px;\r\n}\r\n\r\n.join-raffle-btn {\r\n  color: white;\r\n  border-color: white;\r\n  padding: 5px 10px;\r\n}\r\n\r\n.join-raffle-btn:enabled {\r\n  border-color: white;\r\n}\r\n\r\n.appbar-hamburger-div {\r\n  display: block;\r\n}\r\n\r\n.appbar-menuItem-div {\r\n  display: none;\r\n}\r\n\r\n.appbar-menuItem {\r\n  margin-inline: 10px;\r\n  font-size: 1rem;\r\n  cursor: pointer;\r\n}\r\n\r\n.appbar-mobile-menuItem-div {\r\n  display: flex;\r\n}\r\n\r\n.appbar-mobile-menuItem {\r\n  margin: 5px 10px;\r\n  font-size: 1rem;\r\n  cursor: pointer;\r\n}\r\n\r\n/*LARGE*/\r\n@media screen and (min-width: 768px) {\r\n  .appbar-main-div {\r\n    justify-content: space-between;\r\n  }\r\n\r\n  .appbar-menuItem-div {\r\n    display: flex;\r\n    margin-right: 10px;\r\n  }\r\n\r\n  .appbar-mobile-menuItem-div {\r\n    display: none;\r\n  }\r\n\r\n  .appbar-hamburger-div {\r\n    display: none;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
