const { PathsUrls } = require("Utils/Data");

const menuBtnTypes = {
  mainBtn: "MAIN_BTN",
  redirectBtn: "REDIRECT_BTN",
};

const pageDataLst = [
  { name: "Home", pathName: PathsUrls.home, btnType: menuBtnTypes.mainBtn },
  {
    name: "Your Entries",
    pathName: "https://dashboard.747raffle.com/",
    btnType: menuBtnTypes.redirectBtn,
  },
  {
    name: "Past Draws",
    pathName: PathsUrls.pastDraws,
    btnType: menuBtnTypes.mainBtn,
  },
  {
    name: "Daily Entries",
    pathName: PathsUrls.dailyEntries,
    btnType: menuBtnTypes.mainBtn,
  },
  {
    name: "Raffle Info",
    pathName:
      "https://www.747ppl.com/forums/topic/389-747-daily-raffle-legit-how-to-win/",
    btnType: menuBtnTypes.redirectBtn,
  },
];

// ON PAGE REFRESH, GET ROUTE INDEX
const getInitRouteIndex = (pathName) => {
  return pageDataLst.findIndex((item) => item.pathName === pathName);
};

const getPageLst = (navigateFn, setSelectedMenuItemFn) => {
  function mainMenuItemBtnClick() {
    navigateFn(this.pathName, { replace: "true" });
    setSelectedMenuItemFn(this.index);
  }

  function redirectMenuItemBtnClick() {
    window.open(this.pathName, "_blank");
  }

  return pageDataLst.map((item, index) => {
    return {
      name: item.name,
      index,
      pathName: item.pathName,
      fn:
        item.btnType === menuBtnTypes.mainBtn
          ? mainMenuItemBtnClick
          : redirectMenuItemBtnClick,
    };
  });
};

export { menuBtnTypes, pageDataLst, getInitRouteIndex, getPageLst };
