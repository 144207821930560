import WinMessage from "Components/WinMessage";
import { pageNames } from "Utils/Data";
import PastWinnersInfo from "./PastWinnersInfo";

function PastDrawsPage() {
  return (
    <div style={{ minHeight: "100vh", whiteSpace: "pre-wrap" }}>
      <WinMessage pageName={pageNames.PAST_DRAWS} />
      <PastWinnersInfo />
    </div>
  );
}

export default PastDrawsPage;
