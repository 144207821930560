import { configureStore, createSlice } from "@reduxjs/toolkit";
import funExtremeApi from "./FunExtreme_Apis";
import imiApi from "./IMI_Apis";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    iframeEvent: null,
  },
  reducers: {
    setIframeEvent: (state, action) => {
      state.iframeEvent = action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    [funExtremeApi.reducerPath]: funExtremeApi.reducer,
    [imiApi.reducerPath]: imiApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(funExtremeApi.middleware)
      .concat(imiApi.middleware),
});

export const homeActions = homeSlice.actions;

export default store;
