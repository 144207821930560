import WinMessage from "../../Components/WinMessage";
import { pageNames } from "../../Utils/Data";
import EntriesInfo from "./EntriesInfo";

function DailyEntriesPage() {
  return (
    <div style={{ minHeight: "100vh", whiteSpace: "pre-wrap" }}>
      <WinMessage pageName={pageNames.DAILY_ENTRIES} />
      <EntriesInfo />
    </div>
  );
}

export default DailyEntriesPage;
