import { TableCell, TableRow } from "@mui/material";
import moment from "moment";

const propertyLst = [
  "prizeNumber",
  "entry",
  "blockNumber",
  "drawDateUTC",
  "name",
  "socialCode",
  "prize",
  "isPlayer",
];

function WinnerTableRow({ data }) {
  return (
    <TableRow tabIndex={-1} sx={{}}>
      {propertyLst.map((property, idx) => {
        let element;

        if (property === "blockNumber") {
          element = (
            <TableCell key={property} align="left">
              <a
                href={`https://polygonscan.com/block/${data[property]}`}
                target="_blank"
                rel="noreferrer"
              >
                {data[property]}
              </a>
            </TableCell>
          );
        } else if (property === "drawDateUTC") {
          element = (
            <TableCell key={idx} align="left">
              {moment.utc(data[property]).local().format("DD/MM HH:mm")}
            </TableCell>
          );
        } else if (property === "isPlayer") {
          element = (
            <TableCell key={idx} align="left">
              {data[property] ? "Player" : "Agent"}
            </TableCell>
          );
        } else {
          element = (
            <TableCell key={idx} align="left">
              {data[property]}
            </TableCell>
          );
        }
        return element;
      })}
    </TableRow>
  );
}

export default WinnerTableRow;
