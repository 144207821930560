import { Box, Button, Typography } from "@mui/material";

function ErrorBox({ msg, callbackFn }) {
  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <Typography>{msg}</Typography>

      <Button
        variant="contained"
        disableElevation
        onClick={callbackFn}
        style={{ marginLeft: "10px" }}
      >
        Refresh
      </Button>
    </Box>
  );
}

export default ErrorBox;
