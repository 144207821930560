import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import "./dailyEntries.css";
import WithDailyEntries from "./WithDailyEntries";

const tableHeadingsLst = [
  {
    label: "Date",
  },
  {
    label: "Eligible Entries",
  },
];

function EntriesInfo({ data, feedbackContent }) {
  let tableBodyContent;

  if (data && data.length > 0) {
    tableBodyContent = (
      <TableBody>
        {data.map((row, id) => {
          const dateTime = moment.utc(row.date).local().format("DD/MM HH:mm");

          return (
            <TableRow tabIndex={-1} key={id + 1}>
              <TableCell className="home-table-body-cell" align="left">
                {dateTime}
              </TableCell>
              <TableCell className="home-table-body-cell" align="left">
                {row.entriesOfUsersWithEmail}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  // #endregion

  return (
    <>
      <Box className="dailyEntries-title-div">
        <Typography className="dailyEntries-title">Entries</Typography>
      </Box>

      <div className="dailyEntries-table-div">
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableHeadingsLst.map((column, i) => (
                  <TableCell
                    sx={{
                      paddingRight: "0px",
                      fontSize: "15px",
                      fontWeight: "bold",
                      backgroundColor: "#F2F2F2",
                    }}
                    key={i}
                    align="left"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBodyContent}
          </Table>
        </TableContainer>
      </div>
      {feedbackContent && (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "2vh 0" }}
        >
          {feedbackContent}
        </div>
      )}
    </>
  );
}

export default WithDailyEntries(EntriesInfo);
