const PathsUrls = {
  home: "/",
  pastDraws: "/pastDraws",
  dailyEntries: "/dailyEntries",
};

const IframeEvents = {
  fetchWinner: "Fetch Winners List",
  addWinner: "Add Winner To the Table",
};

const feedbackMsg = {
  loadingMsg: "Loading...",
  errorMsg: "Something Went Wrong...!",
  noRecordsMsg: "No Records Available",
};

const pageNames = {
  HOME: "Home",
  PAST_DRAWS: "Past Draws",
  DAILY_ENTRIES: "Daily Entries",
};

const winMsgIntervalTimerDuration = 12000;

export {
  PathsUrls,
  IframeEvents,
  feedbackMsg,
  pageNames,
  winMsgIntervalTimerDuration,
};
