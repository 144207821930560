// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/play.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public/images/mute.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinwheel-audio-toggle-button-container {
  position: absolute;
  width: 8vh;
  height: 8vh;
  top: 2vh;
  right: 2vh;
  z-index: 3;
}

.spinwheel-audio-toggle-button {
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
}

.button-icon {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.button-icon.play {
  /* background-color: #5cb85c; */
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.button-icon.pause {
  /* background-color: #d9534f; */
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
`, "",{"version":3,"sources":["webpack://./src/Components/SpinWheel/spinWheel.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,QAAQ;EACR,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,eAAe;EACf,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,+BAA+B;EAC/B,yDAAwD;AAC1D;;AAEA;EACE,+BAA+B;EAC/B,yDAAwD;AAC1D","sourcesContent":[".spinwheel-audio-toggle-button-container {\r\n  position: absolute;\r\n  width: 8vh;\r\n  height: 8vh;\r\n  top: 2vh;\r\n  right: 2vh;\r\n  z-index: 3;\r\n}\r\n\r\n.spinwheel-audio-toggle-button {\r\n  cursor: pointer;\r\n  font-size: 14px;\r\n  width: 100%;\r\n  height: 100%;\r\n  border: none;\r\n  background-color: transparent;\r\n}\r\n\r\n.button-icon {\r\n  width: 100%;\r\n  height: 100%;\r\n  background-size: cover;\r\n}\r\n\r\n.button-icon.play {\r\n  /* background-color: #5cb85c; */\r\n  background-image: url(\"../../../public/images/play.png\");\r\n}\r\n\r\n.button-icon.pause {\r\n  /* background-color: #d9534f; */\r\n  background-image: url(\"../../../public/images/mute.png\");\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
