import { pageNames } from "Utils/Data";

const getPageMsgLst = () => {
  return {
    [pageNames.HOME]: [
      "This Christmas season, let the bells of fortune ring! ? With 21,000+ lucky winners. The 747 Xmas Raffle 2024 is here to fill your holiday with joy, excitement, and life-changing surprises!",
    ],
    [pageNames.PAST_DRAWS]: [
      "The 747 Most Wanted and Loved Daily Raffle has started again 🎉 Everyday starting at 7:47 PM I am giving away 103 CASH prizes.",
    ],
    [pageNames.DAILY_ENTRIES]: [
      "The 747 Most Wanted and Loved Daily Raffle has started again 🎉 Everyday starting at 7:47 PM I am giving away 103 CASH prizes.",
    ],
  };
};

const getDefaultRaffleIDLst = () => {
  return [
    { id: "12", name: "747 Xmas Raffle", active: true },
    { id: "3", name: "Daily Raffle", active: false },
  ];
};

export { getPageMsgLst, getDefaultRaffleIDLst };
