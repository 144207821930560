import {
  Box,
  Button,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import Select from "@mui/material/Select";
import { useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./home.css";
import { dataContext } from "Contexts/DataContext";
import {
  useGetWinnersMutation,
  useSearchWinnerMutation,
} from "Services/FunExtreme_Apis";
import { IframeEvents, feedbackMsg } from "Utils/Data";
import ErrorBox from "Components/ErrorBox";
import WinnerTableBody from "Components/WinnerTable/WinnerTableBody";
import WinnerTableRow from "Components/WinnerTable/WinnerTableRow";
import WinnerTableHeader from "Components/WinnerTable/WinnerTableHeader";
import { homeActions } from "Services/Store";

function WinnersInfo() {
  // #region HOOKS
  const iframeEvent = useSelector((state) => state.home.iframeEvent);
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");

  const [apiStateIndex, setApiStateIndex] = useState(0);

  const raffleIDList = useContext(dataContext).RaffleIDList;

  const [currentRaffleID, setCurrentRaffleID] = useState(raffleIDList[0].id);

  const shouldStartTimerRef = useRef(true);

  const [showNewWinner, setShowNewWinner] = useState(false);

  const { iframeRef } = useContext(dataContext);

  const initPageNumber = 1;
  const currentPageNumberRef = useRef(initPageNumber);

  const isFirstFetchWinnerLstCall = useRef(true);

  useEffect(() => {
    if (iframeEvent) {
      // API WILL BE CALLED WHEN TRIGGERED FROM THE IFRAME
      if (iframeEvent === IframeEvents.fetchWinner) {
        if (
          raffleIDList.find((item) => item.id === currentRaffleID).active ||
          isFirstFetchWinnerLstCall.current
        ) {
          fetchWinnerLst(currentRaffleID, currentPageNumberRef.current);
        }

        isFirstFetchWinnerLstCall.current = false;
      } else if (iframeEvent === IframeEvents.addWinner) {
        setShowNewWinner(true);
      }

      dispatch(homeActions.setIframeEvent(null));
    }
    return () => {};
  }, [iframeEvent, currentRaffleID]);

  // #endregion

  // #region APIS

  // GET WINNERS API
  const [
    getWinnersAPI,
    {
      isLoading: isLoadingGetWinnersAPI,
      isSuccess: isSuccessGetWinnersAPI,
      data: getWinnersAPIResponse,
      error: getWinnersAPIError,
    },
  ] = useGetWinnersMutation();

  // SEARCH WINNER API
  const [
    searchWinnerAPI,
    {
      isLoading: isLoadingSearchWinnerAPI,
      isSuccess: isSuccessSearchWinnerAPI,
      data: searchWinnerAPIResponse,
      error: searchWinnerAPIError,
    },
  ] = useSearchWinnerMutation();
  // #endregion

  // #region AD COMPONENT

  const jiliGamingAdOnClick = () => {
    window.open("https://tinyurl.com/747home/casino/slots/all?provider=PPG", "_blank");
  };

  // eslint-disable-next-line no-unused-vars
  const tpgAdOnClick = () => {
    window.open("https://tinyurl.com/747home/casino#/?providers=TPG", "_blank");
  };

  const adContent = (
    <div className="ad-container">
      <Button className="ad-btn" onClick={jiliGamingAdOnClick}>
        <img
          src={`${process.env.PUBLIC_URL}/images/Jili.gif`}
          alt="Dragon-Gaming"
          className="ad-image"
        />
      </Button>
      <Button className="ad-btn" onClick={tpgAdOnClick}>
        <img
          src={`${process.env.PUBLIC_URL}/images/Progmatic-Play.gif`}
          alt="Spinix"
          className="ad-image"
        />
      </Button>
    </div>
  );

  // #endregion

  // #region SEARCH COMPONENT & DROPDOWN

  function changeSearchText(event) {
    setSearchText(event.target.value);
  }

  const handleChangeRaffleID = (event) => {
    setCurrentRaffleID(event.target.value);
    currentPageNumberRef.current = initPageNumber;
    isFirstFetchWinnerLstCall.current = true;
    fetchWinnerLst(event.target.value, initPageNumber);
  };

  function searchBtnClick() {
    fetchSearchWinnerLst();
  }

  function searchClearBtnClick() {
    setApiStateIndex(0);
    setSearchText("");
  }

  const searchFieldContent = (
    <div className="home-search-div">
      <TextField
        value={searchText}
        onChange={changeSearchText}
        placeholder="Social Code"
        className="home-search-textfield"
        inputProps={{
          style: {
            height: "15px",
          },
        }}
        sx={{
          "& fieldset": {
            borderRadius: "10px",
          },
        }}
      />
      <Button
        variant="contained"
        disableElevation
        className="home-search-btn"
        onClick={searchBtnClick}
      >
        Search
      </Button>

      {apiStateIndex === 1 && (
        <Button
          variant="contained"
          disableElevation
          className="home-search-clear-btn"
          onClick={searchClearBtnClick}
        >
          Clear
        </Button>
      )}
    </div>
  );

  const raffleIDDropDownList =
    raffleIDList.length > 0 ? (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <FormControl>
          <InputLabel>Type</InputLabel>
          <Select
            value={currentRaffleID}
            label="Raffle ID"
            onChange={handleChangeRaffleID}
          >
            {raffleIDList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    ) : null;

  const midSection = (
    <Box className="midSection-container">
      {searchFieldContent}
      {raffleIDDropDownList}
    </Box>
  );

  // #endregion

  // #region TABLE COMPONENT

  function fetchWinnerLst(raffleId, currentPage) {
    if (apiStateIndex === 1) {
      iframeRef.current?.contentWindow.setData(0);
      return;
    }
    getWinnersAPI({ pageNumber: currentPage, raffleID: raffleId });

    setShowNewWinner(false);
  }

  function fetchSearchWinnerLst() {
    if (!searchText) return;

    searchWinnerAPI({
      pageNumber: initPageNumber,
      socialCode: searchText,
      raffleID: currentRaffleID,
    });
    setApiStateIndex(1);
  }

  const handlePageChange = (event, value) => {
    currentPageNumberRef.current = value;

    if (apiStateIndex === 0) {
      getWinnersAPI({ pageNumber: value, raffleID: currentRaffleID });
    } else if (apiStateIndex === 1) {
      searchWinnerAPI({
        pageNumber: value,
        socialCode: searchText,
        raffleID: currentRaffleID,
      });
    }
  };

  let tableNewWinnerContent;
  let tableBodyContent;
  let tableFeedbackContent;

  let pageNumber;
  let pageCount;

  function getWinnersAPIStatesMangeFn() {
    if (isLoadingGetWinnersAPI) {
      if (!shouldStartTimerRef.current) shouldStartTimerRef.current = true;

      tableFeedbackContent = <Typography>{feedbackMsg.loadingMsg}</Typography>;
    } else if (getWinnersAPIError) {
      tableFeedbackContent = (
        <ErrorBox
          msg={feedbackMsg.errorMsg}
          callbackFn={fetchWinnerLst(currentRaffleID, initPageNumber)}
        />
      );
    } else if (isSuccessGetWinnersAPI) {
      const winnersLst = getWinnersAPIResponse.winners;

      if (winnersLst.length > 0) {
        pageNumber = getWinnersAPIResponse.currentPage;
        pageCount = getWinnersAPIResponse.totalPages;

        tableBodyContent = <WinnerTableBody data={winnersLst} />;
      } else {
        tableFeedbackContent = (
          <Typography>{feedbackMsg.noRecordsMsg}</Typography>
        );
      }

      if (getWinnersAPIResponse.winner) {
        tableNewWinnerContent = (
          <WinnerTableRow data={getWinnersAPIResponse.winner} />
        );
      }

      if (shouldStartTimerRef.current) {
        try {
          const timeToNextRound = getWinnersAPIResponse.millisToNextRound;

          // RESTART THE TIMER WITH NEW TIME
          if (timeToNextRound > 0) {
            let winnerEntry = getWinnersAPIResponse.winner?.entry;

            if (winnerEntry) {
              // REMOVE SPACES
              winnerEntry = winnerEntry.replace(/\s/g, "");
            }
            iframeRef.current?.contentWindow.setData(
              timeToNextRound,
              winnerEntry,
            );
          } else {
            let winnerEntry = getWinnersAPIResponse.winner?.entry;

            if (winnerEntry) {
              // REMOVE SPACES
              winnerEntry = winnerEntry.replace(/\s/g, "");

              iframeRef.current?.contentWindow.setData(20 * 1000, winnerEntry);
            } else {
              iframeRef.current?.contentWindow.setData(0);
            }
          }

          shouldStartTimerRef.current = false;
        } catch (ex) {
          console.log(ex.message);
        }
      }
    }
  }

  function searchWinnersAPIStatesMangeFn() {
    if (isLoadingSearchWinnerAPI) {
      tableFeedbackContent = <Typography>{feedbackMsg.loadingMsg}</Typography>;
    } else if (searchWinnerAPIError) {
      tableFeedbackContent = (
        <ErrorBox
          msg={feedbackMsg.errorMsg}
          callbackFn={fetchSearchWinnerLst}
        />
      );
    } else if (isSuccessSearchWinnerAPI) {
      const searchWinnersLst = searchWinnerAPIResponse.winners;

      if (searchWinnersLst.length > 0) {
        pageNumber = searchWinnerAPIResponse.currentPage;
        pageCount = searchWinnerAPIResponse.totalPages;

        tableBodyContent = <WinnerTableBody data={searchWinnersLst} />;
      } else {
        tableFeedbackContent = (
          <Typography>{feedbackMsg.noRecordsMsg}</Typography>
        );
      }
    }
  }

  if (apiStateIndex === 0) {
    // GET WINNERS API
    getWinnersAPIStatesMangeFn();
  } else if (apiStateIndex === 1) {
    // SEARCH WINNERS API
    searchWinnersAPIStatesMangeFn();
  }

  // #endregion

  return (
    <>
      {adContent}
      <Box className="home-title-div">
        <Typography className="home-title">Winners</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          width: "100%",
          marginBottom: "40px",
        }}
      >
        {midSection}
      </Box>
      <div className="home-table-div">
        <TableContainer>
          <Table stickyHeader>
            <WinnerTableHeader />
            <TableBody>
              {showNewWinner ? tableNewWinnerContent : null}
              {tableBodyContent}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {tableFeedbackContent && (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "2vh 0" }}
        >
          {tableFeedbackContent}
        </div>
      )}

      {pageCount > 1 && (
        <Pagination
          sx={{ display: "flex", justifyContent: "right", margin: "3vh 5%" }}
          count={pageCount}
          page={pageNumber}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          size="small"
        />
      )}
    </>
  );
}

export default WinnersInfo;
