import { TableCell, TableHead, TableRow } from "@mui/material";

const winnerTableHeaderLst = [
  {
    label: "N° Prize",
  },
  {
    label: "N° Entry",
  },
  {
    label: "Block",
  },
  {
    label: "Date",
  },
  {
    label: "Name",
  },
  {
    label: "Social code",
  },
  {
    label: "Prize",
  },
  {
    label: "Profile",
  },
];

function WinnerTableHeader() {
  return (
    <TableHead>
      <TableRow>
        {winnerTableHeaderLst.map((column, index) => (
          <TableCell
            sx={{
              paddingRight: "0px",
              fontSize: "15px",
              fontWeight: "bold",
              backgroundColor: "#F2F2F2",
            }}
            key={index}
            align="left"
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default WinnerTableHeader;
